import React, { useEffect } from 'react';

// Components
import { NavBar } from '../components/NavBar';
import { Footer } from '../components/Footer';

// Styles
import './scour.scss';

// Read JSON Data
const scour = require('../data/scour.json');

// Function to extract name from slug
const extractNameFromSlug = (slug) => {
	// Replace hyphens with spaces and capitalize the first letter of each word
	return slug.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};

export const Scour = () => {
	// List of links
	const links = [
		{ url: 'https://scour.dev/', label: 'Home' },
		{ url: 'https://scour.dev/products', label: 'Products' },
		{ url: 'https://scour.dev/top-products', label: 'Top Lists' },
		{ url: 'https://scour.dev/top-picks', label: 'Top Picks' },
		{ url: 'https://scour.dev/hot-products', label: 'Hot Products' },
	];

	const categories = [
		{ url: 'https://scour.dev/category/art', label: 'Art' },
		{
			url: 'https://scour.dev/category/tech',
			label: 'Electronic & Devices',
		},
		{
			url: 'https://scour.dev/category/home',
			label: 'Home & DIY',
		},
		{
			url: 'https://scour.dev/category/gaming',
			label: 'Gaming',
		},
		{
			url: 'https://scour.dev/category/music',
			label: 'Music',
		},
		{
			url: 'https://scour.dev/category/office',
			label: 'Office',
		},
		{
			url: 'https://scour.dev/category/personal',
			label: 'Personal care, clothing and health',
		},
		{
			url: 'https://scour.dev/category/pets',
			label: 'Pets',
		},
		{
			url: 'https://scour.dev/category/photography',
			label: 'Photography',
		},
	];

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);
	return (
		<div className="scour__container">
			<header>
				<NavBar menuName="homeOnly" />
			</header>
			<main>
				<h1>Scour Links</h1>
				<ul className="page-links-container">
					<h2>Main:</h2>
					{links.map((link, index) => (
						<li key={index} className="page-links">
							<a href={link.url} target="_blank" rel="noopener noreferrer">
								{link.label}
							</a>
						</li>
					))}
				</ul>
				<ul className="page-links-container">
					<h2>Categories:</h2>
					{categories.map((link, index) => (
						<li key={index} className="page-links">
							<a href={link.url} target="_blank" rel="noopener noreferrer">
								{link.label}
							</a>
						</li>
					))}
				</ul>
				<ul className="page-links-container">
					<h2>Products:</h2>
					{scour.map((item, index) => (
						<li key={index} className="page-links">
							<a
								href={`https://scour.dev/top-products/${item.slug}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{extractNameFromSlug(item.slug)}
							</a>
						</li>
					))}
				</ul>
			</main>
			<footer id="footer">
				<Footer />
			</footer>
		</div>
	);
};
