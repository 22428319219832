import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

// Styles
import './postsection.scss';

export const PostSection = ({ dataPosts }) => {
	return (
		<section className="container-posts">
			<h2>
				<span>My </span>Projects
			</h2>
			<div className="container">
				{dataPosts?.map((item, index) => (
					<div
						key={`Post-${item.id}-${index}`}
						className="post"
						data-aos="zoom-in"
						data-aos-duration="2000"
					>
						<div className="images__img-container-6x4">
							<img
								src={dataPosts[index].image}
								alt={`${item.title} - ${index}`}
							/>
						</div>
						<hr />
						<h4>{item.title}</h4>
						<hr />
						<h5 className="subtitle">
							<span>{item.subtitle}</span>
						</h5>
						<div className="code-container">
							<p className="code">{item.code}</p>
							<p className="code">{item.code2}</p>
						</div>
						{item.link && (
							<a href={item.link} target="_blank" rel="noopener noreferrer">
								<h5 className="btn btn__card">Website</h5>
							</a>
						)}
						{item.link2 && (
							<a href={item.link2} target="_blank" rel="noopener noreferrer">
								<h5 className="btn btn__card_code">Code</h5>
							</a>
						)}
						{item.link3 && (
							<a href={item.link3} target="_blank" rel="noopener noreferrer">
								<h5 className="btn btn__card_code">API</h5>
							</a>
						)}
						{item.content && (
							<Link to={item.content} style={{ textDecoration: 'none' }}>
								<h5 className="btn btn__card_code">Content</h5>
							</Link>
						)}
					</div>
				))}
			</div>
		</section>
	);
};
