import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Routes
import Home from '../pages/Home';
import { About } from '../pages/About';
import { ContactPage } from '../pages/Contact';
import { Projects } from '../pages/Projects';
import { PrivacyPolicy } from '../pages/PrivacyPolicy';
import { CookiePolicy } from '../pages/CookiePolicy';
import { Certificates } from '../pages/Certificates';
import { Scour } from '../pages/Scour';
import { DoDental } from '../pages/DoDental';

export const AppRouter = () => {
	return (
		<div>
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/about" component={About} />
				<Route exact path="/projects" component={Projects} />
				<Route exact path="/contact" component={ContactPage} />
				<Route exact path="/certificates" component={Certificates} />
				<Route exact path="/scour" component={Scour} />
				<Route exact path="/do-dental" component={DoDental} />
				<Route
					exact
					path="/doc/privacy"
					component={PrivacyPolicy}
					rel="nofollow"
				/>
				<Route
					exact
					path="/doc/cookies"
					component={CookiePolicy}
					rel="nofollow"
				/>
				<Redirect to="/" />
			</Switch>
		</div>
	);
};
