import React, { useEffect } from 'react';

// Components
import { NavBar } from '../components/NavBar';
import { Footer } from '../components/Footer';

// Styles
import './scour.scss';

export const DoDental = () => {
	// List of links
	const links = [
		{ url: 'https://dodental.co.uk/', label: 'Home' },
		{ url: 'https://dodental.co.uk/#/treatments', label: 'Treatments' },
		{ url: 'https://dodental.co.uk/#/about', label: 'About' },
		{ url: 'https://dodental.co.uk/#/price-list', label: 'Price List' },
		{ url: 'https://dodental.co.uk/#/contact', label: 'Contact' },
	];

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);
	return (
		<div className="scour__container">
			<header>
				<NavBar menuName="homeOnly" />
			</header>
			<main>
				<h1>Do Dental Links</h1>
				<ul className="page-links-container">
					{links.map((link, index) => (
						<li key={index} className="page-links">
							<a href={link.url} target="_blank" rel="noopener noreferrer">
								{link.label}
							</a>
						</li>
					))}
				</ul>
			</main>
			<footer id="footer">
				<Footer />
			</footer>
		</div>
	);
};
